
import { ListStore } from '@/modules/list';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { sortBy } from 'lodash';

@Component({})
export default class EventTable extends Vue {
  form!: WrappedFormUtils;

  @Prop({ required: true })
  visible!: boolean;

  @Prop({ required: true })
  closeModal!: () => void;

  get lists() {
    return sortBy(ListStore.lists, 'id');
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue();
    ListStore.createNewList(formData.name);
  }

  async select(id: number) {
    await ListStore.fetchListAndSet(id);
    this.closeModal();
  }

  handleCancel() {
    this.closeModal();
  }
}
