
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Node } from '@/modules/list';
import { getLocationImage, getSportImage } from '@/lib/image';

@Component({})
export default class NodeRenderer extends Vue {
  @Prop({ required: true })
  node!: Node;

  sportImage = '';
  locationImage = 'locationImage';

  async getImage() {
    if (this.node.type === 'sport') {
      this.sportImage = await getSportImage(this.node.data.code);
    }

    if (this.node.type === 'competition') {
      this.locationImage = await getLocationImage(this.node.data.locationName);
    }
  }

  mounted() {
    this.getImage();
  }

  canSelect() {
    return this.node.type !== 'sport';
  }

  onSelect(event: any) {
    const { checked } = event.target;
    this.$emit('node-select', { node: this.node, value: checked });
  }
}
