
import Component from 'vue-class-component';
import Vue from 'vue';

import { SportSource } from '@/modules/sportFilter/sportFilter.types';
import { SportFilter } from '@/ui/components/SportFilter';
import { EventStore, PrematchEvent } from '@/modules/event';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { listService, ListStore, NodeSelectEvent } from '@/modules/list';
import { SportStore } from '@/modules/sport';
import EventTable from './components/EventTable.vue';
import PrePress from './components/PrePress.vue';
import ListWorkspace from './components/ListWorkspace.vue';
import SelectListModal from './components/SelectListModal.vue';
import { isEmpty } from 'lodash';

@Component({ components: { SportFilter, EventTable, PrePress, ListWorkspace, SelectListModal } })
export default class NotFound extends Vue {
  modalVisible = true;

  get source() {
    return SportSource;
  }

  get allEvents() {
    return EventStore.eventsArray as PrematchEvent[];
  }

  get sports() {
    return SportStore.allSports;
  }

  get selectedCompetitions() {
    return SportFilterStore.selectedCompetitions;
  }

  get events() {
    return this.allEvents.filter(e => {
      const filterByCompetition = this.selectedCompetitions.length;
      const canFilterBySelectedCompetitions = filterByCompetition && e.competitionId;

      return canFilterBySelectedCompetitions
        ? this.selectedCompetitions.includes(e.competitionId)
        : true;
    });
  }

  get selectedEvents() {
    return ListStore.selectedEvents;
  }

  get nodes() {
    return listService.getNodes(this.events, this.sports, this.selectedEvents);
  }

  get selectedNodes() {
    return listService.getNodes(this.selectedEvents, this.sports, this.selectedEvents);
  }

  get isListActive() {
    return !isEmpty(ListStore.activeList);
  }

  async fetchData() {
    await SportStore.fetchSports();
    await EventStore.getMagazineEvents();
  }

  mounted() {
    this.fetchData();
    ListStore.fetchLists();
  }

  destroyed() {
    ListStore.resetStore();
  }

  onNodeSelect(event: NodeSelectEvent) {
    ListStore.selectNode({ event, nodes: this.nodes });
  }

  closeModal() {
    this.modalVisible = false;
  }

  openModal() {
    this.modalVisible = true;
  }
}
