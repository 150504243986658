
import { ListStore } from '@/modules/list';
import Vue from 'vue';
import Component from 'vue-class-component';

import CompetitionConfig from './CompetitionConfig.vue';
import StatsConfig from './StatsConfig.vue';
import SelectEventsMessage from './SelectEventsMessage.vue';

@Component({ components: { CompetitionConfig, StatsConfig, SelectEventsMessage } })
export default class ListWorkspace extends Vue {
  currentPage = 1;
  pagePreviewFrom = 1;
  pagePreviewTo = 1;
  downloadingPdf = false;

  get selectedCompetitions() {
    return ListStore.selectedCompetitions;
  }

  get pages() {
    return ListStore.pages;
  }

  get pageIndex() {
    return this.currentPage - 1;
  }

  get items() {
    return ListStore.pages[this.pageIndex];
  }

  get availableSpace() {
    return ListStore.availableSpace(this.items);
  }

  get valueFrom() {
    return this.pagePreviewFrom;
  }

  get valueTo() {
    return this.pagePreviewTo;
  }

  handleMenuClick({ key }: any) {
    ListStore.addPageItem({ key, pageIndex: this.pageIndex });
  }

  saveList() {
    ListStore.updateList();
  }

  generatePdf(from?: number, to?: number) {
    this.downloadingPdf = true;
    ListStore.generatePdf({ from, to })
      .then(() => {
        this.downloadingPdf = false;
      })
      .catch(() => {
        this.downloadingPdf = false;
      });
  }

  previewPages() {
    this.generatePdf(this.pagePreviewFrom, this.pagePreviewTo);
  }

  addNewPage() {
    ListStore.createEmptyPage();
    this.currentPage = this.pages.length;
  }

  onPageChange(page: number) {
    this.currentPage = page;
  }

  mounted() {
    if (this.pages.length === 0) {
      ListStore.createEmptyPage();
      this.currentPage = 1;
      this.pagePreviewFrom = 1;
      this.pagePreviewTo = 1;
    }
  }

  removePage() {
    ListStore.removePage(this.pageIndex);
    const nextPage = this.currentPage - 1 <= 0 ? 1 : this.currentPage - 1;
    this.currentPage = nextPage;
  }
}
