export const STAT_LAYOUT = ['1/1', '2/2', '1/1/1']; // '2/1', '1/2'

export const STATS_INPUTS = {
  '1/1': 1,
  '2/2': 2,
  '1/1/1': 3,
  // '2/1': 2,
  // '1/2': 2,
};

export const BLOCKS = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
  'A14',
  'A15',
  'A16',
];

export const BLOCKS_RANGES = {
  A1: { from: 1, to: 1 },
  A2: { from: 1, to: 2 },
  A3: { from: 1, to: 3 },
  A4: { from: 1, to: 4 },
  A5: { from: 1, to: 5 },
  A6: { from: 1, to: 6 },
  A7: { from: 1, to: 7 },
  A8: { from: 1, to: 8 },
  A9: { from: 1, to: 9 },
  A10: { from: 1, to: 10 },
  A11: { from: 1, to: 11 },
  A12: { from: 1, to: 12 },
  A13: { from: 1, to: 13 },
  A14: { from: 1, to: 14 },
  A15: { from: 1, to: 15 },
  A16: { from: 1, to: 16 },
};

// A4 Page size - margins
export const PAGE_SIZE = {
  height: 1035, // maximum 1132 when is margin 0 83 + 30
  width: 756, // maximum 800 when is margin 0
};

export const ROW_SPACING = 0; // ovo je prostor izmednju redova border-spacing
export const PAGE_TITLE_HEIGHT = 29;
export const TABLE_STATS_HEADER = 22;
export const TABLE_HEADER_HEIGHT = 28;
export const TABLE_SUB_HEADER = 28;
export const ROW_HEIGHT = 13 + ROW_SPACING;
