import { api } from '@/lib/api';
import { api as magazineApi } from '@/lib/magazineApi';
import { ListResponse, NewListPayload, StatisticsRowCountResponse } from './list.types';

const fetchLists = () => api.get('odds/soccer-lists/');

const createNewList = (payload: NewListPayload) => {
  return api.post('odds/soccer-lists/', payload) as Promise<ListResponse>;
};

const fetchListById = (id: number) => {
  return api.get(`odds/soccer-lists/${id}/`) as Promise<ListResponse>;
};

const updateList = (payload: any) => {
  return api.put(`odds/soccer-lists/${payload.id}/`, payload);
};

const fetchCompetitiorsCount = (payload: { id: number }) => {
  return api.get(`odds/competitions/${payload.id}/competitors-count/`) as
    | Promise<StatisticsRowCountResponse>
    | undefined;
};

const generatePdf = (payload: any) => {
  return magazineApi.get(`api/pdf/magazine-pages`, {
    params: { id: payload.id, page_from: payload.page_from, page_to: payload.page_to },
  });
};

export const listRepo = {
  fetchLists,
  createNewList,
  fetchListById,
  updateList,
  generatePdf,
  fetchCompetitiorsCount,
};
