
import { BLOCKS, ListStore } from '@/modules/list';
import { SportStore } from '@/modules/sport';
import Vue from 'vue';
import Component from 'vue-class-component';

import { getLocationImage, getSportImage } from '@/lib/image';
import SelectEventsMessage from './SelectEventsMessage.vue';

@Component({ components: { SelectEventsMessage } })
export default class PrePress extends Vue {
  getLocationImage = getLocationImage;

  get selectedCompetitions() {
    return ListStore.selectedCompetitions;
  }

  get competitionBlocks() {
    return ListStore.competitionBlock;
  }

  get blocks() {
    return BLOCKS;
  }

  get sports() {
    return SportStore.allSports;
  }

  get getValue() {
    return (competitionId: number) => this.competitionBlocks[competitionId];
  }

  getSportImage(sportId: number) {
    const sport = this.sports.find(s => s.id === sportId);
    return getSportImage(sport?.code);
  }

  onChange(competition: any, val: string) {
    ListStore.setCompatitionBlock({ competition: competition.id, block: val });
    this.$forceUpdate();
  }
}
