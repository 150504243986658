import Vue from 'vue';
import { getModule, VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import to from 'await-to-js';
import { v4 as uuidv4 } from 'uuid';
import { max, each } from 'lodash';

import store from '@/store';
import { eventService, EventStore, PrematchEvent } from '../event';
import {
  ListResponse,
  Node,
  NodeSelectEvent,
  Page,
  PageItem,
  SelectedCompetition,
} from './list.types';
import { listService } from './list.service';
import {
  PAGE_SIZE,
  TABLE_HEADER_HEIGHT,
  TABLE_SUB_HEADER,
  TABLE_STATS_HEADER,
} from './list.constants';

const initialState = () => ({
  selectedEvents: [],
  lists: [],
  activeList: [],
  competitionBlock: {},
  pages: [],
});

@Module({ dynamic: true, store, name: 'list', namespaced: true })
class List extends VuexModule {
  selectedEvents: PrematchEvent[] = [];
  lists: ListResponse[] = [];
  activeList: ListResponse | null = null;
  competitionBlock: Record<number, string> = initialState().competitionBlock;
  pages: Page[] = initialState().pages;

  get availableSpace() {
    return (page: Page) => {
      const maxHeight = PAGE_SIZE.height;
      const blockHeader = TABLE_SUB_HEADER + TABLE_HEADER_HEIGHT;
      const statisticsHeader = TABLE_STATS_HEADER;

      let availableHeight = maxHeight;

      const { theaders, sheaders, rows } = listService.calculatePageInfo(page);

      availableHeight -= theaders * blockHeader;
      availableHeight -= sheaders * statisticsHeader;

      const rowSize = availableHeight / (rows || 1);
      if (rowSize > 15) availableHeight = availableHeight - rows * 15;
      else if (rowSize < 10) availableHeight = availableHeight - rows * 10;
      else availableHeight = availableHeight - rows * rowSize;

      const usedHeight = `${(100 - (availableHeight * 100) / maxHeight).toFixed()}%`;

      let rowHeight = `${+rowSize.toFixed(2)}`;
      if (rowSize === Infinity) rowHeight = '0px';
      else if (rowSize > 15) rowHeight = '13px';
      else rowHeight = rowHeight + 'px';
      return { rowHeight, usedHeight };
    };
  }

  get selectedCompetitions() {
    return listService.selectedCompetitions(this.selectedEvents);
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  setLists(lists: any) {
    this.lists = lists;
  }

  @Mutation
  addPageItem({ key, pageIndex }: any) {
    const type = key === 'c' ? 'competition' : 'statistics';
    const page = this.pages[pageIndex];
    if (!page) return;

    if (type === 'competition') {
      this.pages[pageIndex].push({
        id: uuidv4(),
        type: 'competition',
        competition: null,
        competitionName: '',
        block: '',
        events: 0,
        blockRangeFrom: 1,
        blockRangeTo: 1,
      });
    } else {
      this.pages[pageIndex].push({
        id: uuidv4(),
        type: 'statistics',
        layout: '',
        competitions: [],
        rows: 0,
        competitionRows: [],
      });
    }
  }

  @Mutation
  setActiveList(list: ListResponse) {
    this.activeList = list;
  }

  @Mutation
  addNewList(list: ListResponse) {
    this.lists.push(list);
  }

  @Mutation
  createEmptyPage() {
    if (!this.activeList) return;
    this.pages.push([]);
  }

  @Mutation
  removePage(pageIndex: number) {
    this.pages.splice(pageIndex, 1);
  }

  @Mutation
  setPageCompetitionBlock({
    pageIndex,
    itemIndex,
    value,
    from,
    to,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: string;
    from: number;
    to: number;
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'competition') {
      item.block = value;
      item.blockRangeFrom = from;
      item.blockRangeTo = to;
    }
  }

  @Mutation
  setPageCompetitionBlockBlockRangeFrom({
    pageIndex,
    itemIndex,
    value,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: number;
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'competition') {
      item.blockRangeFrom = value;
    }
  }

  @Mutation
  setPageCompetitionBlockBlockRangeTo({
    pageIndex,
    itemIndex,
    value,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: number;
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'competition') {
      item.blockRangeTo = value;
    }
  }

  @Mutation
  removePageItem({ pageIndex, itemIndex }: { pageIndex: number; itemIndex: number }) {
    this.pages[pageIndex].splice(itemIndex, 1);
  }

  @Mutation
  setPageCompetition({
    pageIndex,
    itemIndex,
    value,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: SelectedCompetition;
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'competition') {
      item.competition = value.id;
      item.events = value.events;
      item.competitionName = value.name;
    }
  }

  @Mutation
  setPageStatLayout({
    pageIndex,
    itemIndex,
    value,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: string;
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'statistics') {
      item.layout = value;
      item.competitions = [];
      item.competitionRows = [];
      item.rows = 0;
    }
  }

  @Mutation
  setPageStatCompetitions({
    pageIndex,
    itemIndex,
    value,
    competitionRows,
  }: {
    pageIndex: number;
    itemIndex: number;
    value: number[];
    competitionRows: number[];
  }) {
    const item = this.pages[pageIndex][itemIndex];
    if (item.type === 'statistics') {
      item.competitions = value;
      item.competitionRows = competitionRows;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (competitionRows.length > 1) item.rows = max(competitionRows)!;
      else if (competitionRows.length === 1) item.rows = competitionRows[0];
      else item.rows = 0;
    }
  }

  @Mutation
  setCompatitionBlock({ competition, block }: { competition: number; block: string }) {
    this.competitionBlock[competition] = block;
    Vue.set(this.competitionBlock, competition, block);
  }

  @Mutation
  toggleEvent({ event, value }: { event: PrematchEvent; value: boolean }) {
    if (value) {
      this.selectedEvents.push(event);
    } else {
      this.selectedEvents = this.selectedEvents.filter(e => e.intKey !== event.intKey);
    }

    this.selectedEvents = eventService.sortEvents(this.selectedEvents);
  }

  @Mutation
  pickCompetitionBlock(list: ListResponse) {
    this.competitionBlock = list.competition_blocks;
  }

  @Mutation
  pickPages(list: ListResponse) {
    this.pages = list.list_content.pages || [];
  }

  @Action
  onEventSelect({ event }: { event: NodeSelectEvent; nodes: Node[] }) {
    if (event.node.type !== 'event') return;
    const data = event.node.data;
    const isAlreadySelected = this.selectedEvents.map(e => e.intKey).includes(data.intKey);

    this.toggleEvent({ event: data, value: !isAlreadySelected });
  }

  @Action
  onCompetitionSelect({ event, nodes }: { event: NodeSelectEvent; nodes: Node[] }) {
    if (event.node.type !== 'competition') return;

    const competition = event.node.data;

    nodes.forEach(node => {
      if (node.type === 'event' && node.data.competitionId === competition.id) {
        this.toggleEvent({ event: node.data, value: event.value });
      }
    });
  }

  @Action
  onSportSelect({ event, nodes }: { event: NodeSelectEvent; nodes: Node[] }) {
    if (event.node.type !== 'sport') return;

    const sport = event.node.data;

    nodes.forEach(node => {
      if (node.type === 'event' && node.data.sportId === sport.id) {
        this.toggleEvent({ event: node.data, value: event.value });
      }
    });
  }

  @Action
  selectNode({ event, nodes }: { event: NodeSelectEvent; nodes: Node[] }) {
    const handlers = {
      event: this.onEventSelect,
      competition: this.onCompetitionSelect,
      sport: this.onSportSelect,
    };

    handlers[event.node.type]({ event, nodes });
  }

  @Action
  async fetchLists() {
    const [err, res] = await to(listService.fetchLists());
    if (err) return;
    this.setLists(res);
  }

  @Action
  async createNewList(name: string) {
    const [err, res] = await to(listService.createList({ name }));
    if (err) return;
    if (!res) return;
    this.addNewList(res);
  }

  @Action
  async fetchListAndSet(id: number) {
    const [err, res] = await to(listService.fetchListById(id));
    if (err) return;
    this.setActiveList(res as ListResponse);
    this.pickSelectedEvents(res as ListResponse);
    this.pickCompetitionBlock(res as ListResponse);
    this.pickPages(res as ListResponse);
  }

  @Action
  pickSelectedEvents(list: ListResponse) {
    Object.keys(EventStore.events).forEach((key: string) => {
      if (list.events.includes(Number(key.replace('e_', '')))) {
        this.selectedEvents.push(EventStore.events[key]);
      }
    });
  }

  @Action
  updateList() {
    if (!this.activeList) return;
    const competitions = this.pages.reduce((stats: any[], page: any) => {
      each(page, (table: PageItem) => {
        if (table.type === 'statistics') stats.push(...table.competitions);
      });
      return [...stats];
    }, []);

    const payload: ListResponse = {
      ...this.activeList,
      events: this.selectedEvents.map(e => e.intKey),
      competition_blocks: this.competitionBlock,
      list_content: { pages: this.pages },
      competitions,
    };

    listService.updateList(payload);
  }

  @Action
  getCompetitiorsCount(id: number) {
    if (!this.activeList) return;

    const payload: any = {
      id,
    };

    return listService.fetchCompetitiorsCount(payload);
  }

  @Action
  async generatePdf(page: { from?: number; to?: number }) {
    if (!this.activeList) return;

    const payload: ListResponse = {
      ...this.activeList,
      events: this.selectedEvents.map(e => e.intKey),
      competition_blocks: this.competitionBlock,
      list_content: { pages: this.pages },
      page_from: page.from,
      page_to: page.to,
    };

    const res = await listService.generatePdf(payload);
    //@ts-ignore
    const blob = new Blob([res], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  @Action
  blobToBase64(blob: any) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}

export const ListStore = getModule(List);
