
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Node } from '@/modules/list';
import NodeRenderer from './NodeRenderer.vue';

@Component({ components: { NodeRenderer } })
export default class EventTable extends Vue {
  @Prop({ required: true })
  nodes!: Node[];

  @Prop()
  onSelect!: () => void;
}
