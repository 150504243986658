
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

import { ListStore, PageStat, STATS_INPUTS, STAT_LAYOUT } from '@/modules/list';

@Component({})
export default class StatsConfig extends Vue {
  layoutOptions = STAT_LAYOUT;
  loadingStatsInfo = false;

  @Prop()
  pageIndex!: number;

  @Prop()
  itemIndex!: number;

  get item() {
    return ListStore.pages[this.pageIndex][this.itemIndex] as PageStat;
  }

  get selectedCompetitions() {
    return ListStore.selectedCompetitions.filter(item => item.sportId === 1);
  }

  get numberOfInputs() {
    if (!this.item) return 0;
    // @ts-ignore
    return STATS_INPUTS[this.item.layout];
  }

  get inputs() {
    return Array(this.numberOfInputs).fill(null);
  }

  onLayoutOptionChange(value: string) {
    ListStore.setPageStatLayout({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      value: value,
    });
  }

  filterOption(input: any, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  async onCompetition(index: number, value: number) {
    const competitions = this.item.competitions;
    const competitionRows = this.item.competitionRows;
    competitions[index] = value;
    this.loadingStatsInfo = true;
    try {
      const response = await ListStore.getCompetitiorsCount(value);
      competitionRows[index] = response ? response.count : 0;
    } catch {
      return;
    } finally {
      this.loadingStatsInfo = false;
    }
    await ListStore.setPageStatCompetitions({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      value: competitions,
      competitionRows,
    });
    this.$forceUpdate();
  }

  remove() {
    ListStore.removePageItem({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
    });
  }
}
