import { PrematchEvent } from '@/modules/event';
import { Sports } from '../sport';
import { listRepo } from './list.repo';
import { ListResponse, NewListPayload, Node, Page, SelectedCompetition } from './list.types';

class ListService {
  getNodes(events: PrematchEvent[], sports: Sports[], selectedEvents: PrematchEvent[]): Node[] {
    const result: Node[] = [];
    // this sort is preventing repeated NODES for sports and competition
    events = events.sort((a, b) => a.sportId - b.sportId || a.competitionId - b.competitionId);
    events.forEach((event, index) => {
      if (event.sportId !== events[index - 1]?.sportId) {
        const sport = sports.find(s => s.id === event.sportId);
        if (!sport) return;
        result.push({
          data: sport,
          type: 'sport',
          selected: this.isGroupSelected(events, index, selectedEvents, `s_${sport.id}`),
        });
      }

      if (event.competitionId !== events[index - 1]?.competitionId) {
        result.push({
          data: {
            id: event.competitionId,
            name: event.competitionName,
            locationName: event.locationName,
          },
          type: 'competition',
          selected: this.isGroupSelected(events, index, selectedEvents, `c_${event.competitionId}`),
        });
      }

      result.push({
        data: event,
        type: 'event',
        selected: this.isEventSelected(event, selectedEvents),
      });
    });
    return result;
  }

  isEventSelected(event: PrematchEvent, selectedEvents: PrematchEvent[]) {
    return selectedEvents.map(e => e.intKey).includes(event.intKey);
  }

  isGroupSelected(
    events: PrematchEvent[],
    index: number,
    selectedEvents: PrematchEvent[],
    name: string
  ) {
    let result = false;

    for (let i = index; i < events.length; i = i + 1) {
      const event = events[i];

      const limit = name !== `c_${event.competitionId}` && name !== `s_${event.sportId}`;
      if (limit) {
        return result;
      }
      if (this.isEventSelected(event, selectedEvents)) {
        result = true;
      }
    }

    return result;
  }

  selectedCompetitions(events: PrematchEvent[]): SelectedCompetition[] {
    const tempMap: any = {};

    for (const event of events) {
      const key = `c_${event.competitionId}`;

      if (tempMap[key]) {
        tempMap[key].events = tempMap[key].events + 1;
      } else {
        tempMap[key] = {
          id: event.competitionId,
          name: event.competitionName,
          sportId: event.sportId,
          location: event.locationName,
          events: 1,
        };
      }
    }

    return Object.values(tempMap) as SelectedCompetition[];
  }

  fetchLists() {
    return listRepo.fetchLists();
  }

  createList(payload: NewListPayload) {
    return listRepo.createNewList(payload);
  }

  fetchListById(id: number) {
    return listRepo.fetchListById(id);
  }

  updateList(list: ListResponse) {
    return listRepo.updateList(list);
  }

  fetchCompetitiorsCount(payload: { id: number }) {
    return listRepo.fetchCompetitiorsCount(payload);
  }

  generatePdf(list: ListResponse) {
    return listRepo.generatePdf(list);
  }

  calculatePageInfo(page: Page) {
    let theaders = 0;
    let sheaders = 0;
    let rows = 0;
    page.forEach(item => {
      if (item.type === 'competition') {
        const thead = item.blockRangeTo - item.blockRangeFrom + 1;
        theaders += thead;
        rows = rows + thead * item.events;
      } else if (item.type === 'statistics') {
        sheaders += 1;
        rows += item.rows;
        if (item.layout === '1/1') rows += 1;
      }
    });
    return { theaders, sheaders, rows };
  }
}

export const listService = new ListService();
