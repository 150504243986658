
import Vue from 'vue';
import Component from 'vue-class-component';

import { BLOCKS, BLOCKS_RANGES, ListStore, PageCompetition } from '@/modules/list';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class CompetitionConfig extends Vue {
  @Prop()
  pageIndex!: number;

  @Prop()
  itemIndex!: number;

  get item() {
    return ListStore.pages[this.pageIndex][this.itemIndex] as PageCompetition;
  }

  get selectedCompetitions() {
    return ListStore.selectedCompetitions;
  }

  get blocks() {
    return BLOCKS;
  }

  get blocksRanges() {
    return BLOCKS_RANGES;
  }

  get blocksRangeFrom() {
    if (this.item.block) {
      const list: number[] = [];
      // @ts-ignore-next-line
      const blockRange = this.blocksRanges[this.item.block];
      const max = this.item.blockRangeTo || blockRange.to;
      for (let index = 1; index <= max; index++) {
        list.push(index);
      }
      return list;
    }
    return [];
  }

  get blocksRangeTo() {
    if (this.item.block) {
      const list: number[] = [];
      // @ts-ignore-next-line
      const blockRange = this.blocksRanges[this.item.block];
      const min = this.item.blockRangeFrom || blockRange.from;
      for (let index = min; index <= blockRange.to; index++) {
        list.push(index);
      }
      return list;
    }
    return [];
  }

  filterOption(input: any, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  onBlockChange(value: string) {
    // @ts-ignore-next-line
    const blockRange = this.blocksRanges[value];
    ListStore.setPageCompetitionBlock({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      value: value,
      to: blockRange.to,
      from: blockRange.from,
    });
  }

  onBlockRangeFromChange(value: number) {
    ListStore.setPageCompetitionBlockBlockRangeFrom({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      value,
    });
  }

  onBlockRangeToChange(value: number) {
    ListStore.setPageCompetitionBlockBlockRangeTo({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      value,
    });
  }

  onCompetitionChange(value: number) {
    ListStore.setPageCompetition({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: this.selectedCompetitions.find(comp => comp.id === value)!,
    });
  }

  remove() {
    ListStore.removePageItem({
      pageIndex: this.pageIndex,
      itemIndex: this.itemIndex,
    });
  }
}
